@tailwind base;
@tailwind components;
@tailwind utilities;

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  font-size: 18px;
  list-style-type: square;
  position: relative;
}
.purple {
  background: linear-gradient(90deg, #667eea 0%, #764ba2 100%);
}
nav {
  z-index: 1;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.modal-overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
}

.modal {
  opacity: 1;
  z-index: 2000;
  background-color: #fff;
  min-width: 300px;
  max-width: 500px;
  margin: 20px;
  text-align: center;
}
